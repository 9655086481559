import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "../public/css/style.css"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, faUser, faCog, faSignOutAlt, faHome, faEnvelope, faBell, faUserFriends } from '@fortawesome/free-solid-svg-icons'
library.add(faBars, faUser, faCog, faSignOutAlt, faHome, faEnvelope, faBell, faUserFriends);

import "xp.css/dist/98.css";

const myApp = createApp(App);

myApp.use(router);
myApp.component('font-awesome-icon', FontAwesomeIcon);
myApp.mount('#app')
