<template>
    <div class="terminal" @click="focusInput">

    <pre>Gus&#10094;R&#10095; BOS [Version 1.0.0]
&#10094;C&#10095; Copyright Augusto Gouveia 2022-2022.
      <br>Welcome to my personal website!<br>You're a bit too early to the party.<br>Come back later when I have some commands ready for you!
      <br>C:&#92;GUS&#92;PORTFOLIO><span id="commandInput" class="input" ref="input" autofocus contenteditable ></span><span class="cursor"></span>
    </pre>

    </div>
</template>

<script>
// @ is an alias to /src
// import Taskbar from '@/components/Taskbar.vue';

export default {
    name: 'HomeView',
    // components: {
    //     Taskbar
    // },
    methods: {
        startTimer () {
            this.$nextTick(() => {

                //this one works perfectly
                this.$refs.input.focus()

            })
        }, /* END startTimer */

        focusInput () {
                this.$refs.input.focus()

        }, /* END focusInput */
    },
    mounted () {
        onkeydown = (event) => {
            if(event.key === 'Enter') {
            this.focusInput();
            return false;
            }
        };
        this.startTimer()
    },
    created() {
        document.title = "Gus BOS [Version 1.0.0]";
    }
}
</script>

<style>
.terminal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    color: #fff;
    font-family: "Perfect DOS VGA 437 Win", monospace;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
}
pre{
    height: 100%;
    box-shadow: none !important;
}
.cursor {
    content: '';
    display: inline-block;
    width: 10px;
    height: 3px;
    background: #c0c0c0;
    margin-bottom: -3px;
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.input{
    background: transparent !important;
    border: none !important;
    outline: none !important;
    color: #c0c0c0;
    font-family: "Perfect DOS VGA 437 Win", monospace;
    font-size: 1rem;
    caret-color: transparent !important;
    box-shadow: none !important;
}

.input :focus{
    outline: none !important;
    box-shadow: none !important;

    border: 0;
}

*[contenteditable="true"]{
    display: inline-block;
    caret-color: transparent !important;

}

</style>